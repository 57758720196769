import React, { useState, useEffect, useContext } from "react";
import i18n from "i18next";
import { withNamespaces } from "react-i18next";
import { LoadingContext } from "../../context/LoadingContext";
import es_flag from "../../../../src/images/navBar/es-flag.png";
import en_flag from "../../../../src/images/navBar/en-flag.png";

const ButtonLanguage = () => {
  const { windowWidth } = useContext(LoadingContext);

  // Estado para manejar el idioma actual
  const [language, setLanguage] = useState(i18n.language || "es"); // Idioma inicial seguro

  useEffect(() => {
    // Sincronizar el estado del idioma con i18n.language al montar o cambiar
    setLanguage(i18n.language);
  }, [i18n.language]);

  function handleSelectChange(event) {
    const selectedValue = event.target.value.toLowerCase();
    setLanguage(selectedValue);
    i18n.changeLanguage(selectedValue);
  }

  const flagSrc = language === "en" ? en_flag : es_flag;

  return (
    <>
      {windowWidth > 1700 ? (
        <div className="cursor-pointer m-auto bottomLanguageResponsive language-select">
          <img
            src={flagSrc}
            alt={`${language} Flag`}
            className="flag_Language"
          />
          <select
            className="buttomLanguage"
            onChange={(event) => handleSelectChange(event)}
            value={language === "es" ? "ES" : "EN"}
          >
            <option value={"EN"}>EN</option>
            <option value={"ES"}>ES</option>
          </select>
        </div>
      ) : (
        <div className="cursor-pointer m-auto buttomLanguage2 language-select">
          <img
            src={flagSrc}
            alt={`${language} Flag`}
            className="flag_Language"
          />
          <select
            className="buttomLanguage"
            onChange={(event) => handleSelectChange(event)}
            value={language === "es" ? "ES" : "EN"}
          >
            <option value={"EN"}>EN</option>
            <option value={"ES"}>ES</option>
          </select>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(ButtonLanguage);
